import { Admin, Layout, LayoutProps, Resource } from 'react-admin';
import { MenuItemList } from './MenuItem/MenuItemList';
import {
    AdminIcon,
    CategoryIcon,
    CustomerIcon,
    DetailsIcon,
    DocsIcon,
    GalleryIcon,
    MenuIcon,
    ParagraphsIcon,
    PlansIcon,
    QuestionsIcon,
    ServiceIcon,
    TeamIcon,
    VariantsIcon,
    VideoIcon,
} from './Icons';
import { MenuItemCreate } from './MenuItem/MenuItemCreate';
import { CategoryList } from './Category/CategoryList';
import { CategoryCreate } from './Category/CategoryCreate';
import { CategoryUpdate } from './Category/CategoryUpdate';
import { MenuItemUpdate } from './MenuItem/MenuItemUpdate';
import { MenuItemShow } from './MenuItem/MenuItemShow';
import { MenuDetailsCreate } from './MenuDetails/MenuDetailsCreate';
import { MenuDetailsList } from './MenuDetails/MenuDetailsList';
import { MenuDetailsShow } from './MenuDetails/MenuDetailsShow';
import { MenuDetailsUpdate } from './MenuDetails/MenuDetailsUpdate';
import { QuestionsList } from './Questions/QuestionsList';
import { QuestionsCreate } from './Questions/QuestionsCreate';
import { QuestionsUpdate } from './Questions/QuestionsUpdate';
import { QuestionsShow } from './Questions/QuestionsShow';
import { TeammateCreate } from './Teammate/TeammateCreate';
import { TeammateList } from './Teammate/TeammateList';
import { TeammateShow } from './Teammate/TeammateShow';
import { TeammateUpdate } from './Teammate/TeammateUpdate';
import { PhotoCreate } from './Photo/PhotoCreate';
import { PhotoList } from './Photo/PhotoList';
import { PhotoShow } from './Photo/PhotoShow';
import { PhotoUpdate } from './Photo/PhotoUpdate';
import { VideoCreate } from './Video/VideoCreate';
import { VideoList } from './Video/VideoList';
import { VideoShow } from './Video/VideoShow';
import { VideoUpdate } from './Video/VideoUpdate';
import { DocCreate } from './Doc/DocCreate';
import { DocList } from './Doc/DocList';
import { DocUpdate } from './Doc/DocUpdate';
import authProvider from '../providers/authProvider';
import { dataProvider } from '../providers/dataProvider';
import { UserCreate } from './User/UserCreate';
import { UserList } from './User/UserList';
import { UserUpdate } from './User/UserUpdate';
import { AppformList } from './Appforms/AppformList';
import { AppformCreate } from './Appforms/AppformCreate';
import { AppformUpdate } from './Appforms/AppformUpdate';
import { ProductShow } from './Product/ProductShow';
import { ProductUpdate } from './Product/ProductUpdate';
import { ProductCreate } from './Product/ProductCreate';
import { ProductList } from './Product/ProductList';
import { ProductVariantList } from './ProductVariant/ProductVariantList';
import { ProductVariantCreate } from './ProductVariant/ProductVariantCreate';
import { ProductVariantUpdate } from './ProductVariant/ProductVariantUpdate';
import { ProductPlanCreate } from './ProductPlan/ProductPlanCreate';
import { ProductPlanList } from './ProductPlan/ProductPlanList';
import { ProductPlanUpdate } from './ProductPlan/ProductPlanUpdate';
import { ParagraphsCreate } from './Paragraphs/ParagraphsCreate';
import { ParagraphsList } from './Paragraphs/ParagraphsList';
import { ParagraphsShow } from './Paragraphs/ParagraphsShow';
import { ParagraphsUpdate } from './Paragraphs/ParagraphsUpdate';

const CustomLayout = (props: LayoutProps) => <Layout {...props} />;

function App() {
    return (
        <Admin
            dataProvider={dataProvider}
            title='Админ панель rst360.ru'
            authProvider={authProvider}
            layout={CustomLayout}
        >
            <Resource
                name='appforms'
                list={AppformList}
                options={{ label: 'Заявки' }}
                create={AppformCreate}
                edit={AppformUpdate}
                icon={CustomerIcon}
            />
            <Resource
                name='products'
                list={ProductList}
                create={ProductCreate}
                edit={ProductUpdate}
                show={ProductShow}
                options={{ label: 'Услуги' }}
                icon={ServiceIcon}
                recordRepresentation='title'
            />
            <Resource
                name='product-variants'
                list={ProductVariantList}
                create={ProductVariantCreate}
                edit={ProductVariantUpdate}
                options={{ label: 'Варианты продаж' }}
                icon={VariantsIcon}
                recordRepresentation='title'
            />
            <Resource
                name='product-plans'
                list={ProductPlanList}
                create={ProductPlanCreate}
                edit={ProductPlanUpdate}
                options={{ label: 'Цены' }}
                icon={PlansIcon}
            />
            <Resource
                name='categories'
                list={CategoryList}
                options={{ label: 'Категории' }}
                create={CategoryCreate}
                edit={CategoryUpdate}
                icon={CategoryIcon}
                recordRepresentation='name'
            />
            <Resource
                name='menu/items'
                list={MenuItemList}
                create={MenuItemCreate}
                edit={MenuItemUpdate}
                show={MenuItemShow}
                options={{ label: 'Позиции' }}
                icon={MenuIcon}
                recordRepresentation='title'
            />

            <Resource
                name='menu/details'
                list={MenuDetailsList}
                create={MenuDetailsCreate}
                edit={MenuDetailsUpdate}
                show={MenuDetailsShow}
                options={{ label: 'Топ-лист' }}
                icon={DetailsIcon}
            />
            <Resource
                name='paragraphs'
                list={ParagraphsList}
                create={ParagraphsCreate}
                edit={ParagraphsUpdate}
                show={ParagraphsShow}
                options={{ label: 'Параграфы' }}
                icon={ParagraphsIcon}
            />
            <Resource
                name='questions'
                list={QuestionsList}
                create={QuestionsCreate}
                edit={QuestionsUpdate}
                show={QuestionsShow}
                options={{ label: 'FAQ' }}
                icon={QuestionsIcon}
            />
            <Resource
                name='teammates'
                list={TeammateList}
                create={TeammateCreate}
                edit={TeammateUpdate}
                show={TeammateShow}
                options={{ label: 'Работники' }}
                icon={TeamIcon}
            />
            <Resource
                name='gallery/photos'
                list={PhotoList}
                create={PhotoCreate}
                edit={PhotoUpdate}
                show={PhotoShow}
                options={{ label: 'Фотографии' }}
                icon={GalleryIcon}
            />
            <Resource
                name='gallery/videos'
                list={VideoList}
                create={VideoCreate}
                edit={VideoUpdate}
                show={VideoShow}
                options={{ label: 'Видео' }}
                icon={VideoIcon}
                recordRepresentation='title'
            />
            <Resource
                name='docs'
                list={DocList}
                create={DocCreate}
                edit={DocUpdate}
                options={{ label: 'Документы' }}
                icon={DocsIcon}
            />
            <Resource
                name='users'
                list={UserList}
                create={UserCreate}
                edit={UserUpdate}
                options={{ label: 'Админы' }}
                icon={AdminIcon}
            />
        </Admin>
    );
}

export default App;
