import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    TextField,
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    RichTextField,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const QuestionsShow: FC<Props> = () => {
    return (
        <Show actions={<EditingTopToolbar withEdit />}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='title' label='Заголовок' />
                <ReferenceField
                    label='Категория'
                    source='category.id'
                    reference='categories'
                />
                <RichTextField source='answer' label='Ответ' />
                <CustomImageField
                    source='url'
                    label={'Фото'}
                    width='200px'
                    height='150px'
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <DateField
                    showTime
                    source='createdDate'
                    label='Дата создания'
                />
            </SimpleShowLayout>
        </Show>
    );
};
