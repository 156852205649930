import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    maxLength,
    minValue,
    BooleanInput,
    minLength,
    regex,
    Edit,
} from 'react-admin';
import { ContentPurposeChoices, SLUG_REGEX } from '../../utils/const';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const validateName = [
    required(),
    maxLength(32, 'Максимум 32 символа'),
    minValue(3),
];
const validateSlug = [
    required(),
    minLength(1),
    maxLength(14),
    regex(SLUG_REGEX, 'Только английские буквы'),
];

export const CategoryUpdate: FC<Props> = () => {
    return (
        <Edit
            title={'Внести изменения в категорию'}
            actions={<EditingTopToolbar />}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='name'
                    label='Название'
                    validate={validateName}
                    fullWidth
                    helperText='До 32 символов. Отображается для клиента'
                />
                <TextInput
                    source='slug'
                    label='Именной идентификатор'
                    validate={validateSlug}
                    fullWidth
                    helperText='Максимум 14 английских букв или цифр. Уникален для каждого типа контента'
                />
                <SelectInput
                    disabled
                    fullWidth
                    source='purpose'
                    label='Тип контента'
                    validate={required()}
                    choices={ContentPurposeChoices}
                />
                <BooleanInput
                    fullWidth
                    source='active'
                    label='Активность'
                    defaultValue={true}
                />
            </SimpleForm>
        </Edit>
    );
};
