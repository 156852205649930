import { FC } from 'react';
import {
    ImageFieldProps,
    TextFieldProps,
    UrlFieldProps,
    useRecordContext,
} from 'react-admin';

interface Props extends UrlFieldProps {
    source: string;
    base?: string;
    withoutHeading?: boolean;
    withControls?: boolean;
    width?: string;
    height?: string;
}

export const CustomVideoField: FC<Props> = (props) => {
    const record = useRecordContext();
    if (!record) return null;
    const { width, height } = props;
    console.log(record)
    return (
        <>
            {record[props.source] ? (
                <div className='video-field'>
                    {props.withoutHeading ? '' : <span>Превью:</span>}
                    <video
                        style={
                            width || height ? { width, height } : {}
                        }
                        controls={props.withControls}
                        src={`${props.base || ''}${record[props.source]}`}
                        width='400px'
                        height={'300px'}
                    />
                </div>
            ) : (
                <span className={'no-value-column'}>{'<<Отсутствует>>'}</span>
            )}
        </>
    );
};
