import { required, maxLength, minValue, minLength, regex } from 'react-admin';
import { SLUG_REGEX } from '../../utils/const';

export const validateTitle = [
    maxLength(150, 'Максимум 150 символов'),
    minLength(3),
];
export const validateSlug = [
    required(),
    minLength(1),
    maxLength(14),
    regex(SLUG_REGEX, 'Только английские буквы или цифры'),
];

export const validateValue = [
    required(),
    maxLength(1000, 'Максимум 1000 символов в одном параграфе'),
    minLength(3, 'Минимум 3 символов'),
];
