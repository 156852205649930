import { AUTH_URL } from './const';
import { getBasicRequest } from './getBasicRequest';
import { setTokenToStorage } from './setTokenToStorage';

export const refreshToken = async () => {
    const request = getBasicRequest({
        url: `${AUTH_URL}/refresh`,
    });
    try {
        const userData = await fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }

            setTokenToStorage(response);

            return response.json();
        });
        console.log(userData);
        if (userData) {
            localStorage.setItem('user', JSON.stringify(userData));
            return Promise.resolve();
        }
    } catch (e: any) {
        return Promise.reject({ message: false });
    }
};
