import { FC, HTMLAttributes, DetailedHTMLProps } from 'react';
import { BASE_URL } from '../utils/const';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    url: string;
}

export const ImageContainer: FC<Props> = ({ url, children, ...props }) => {
    
    return (
        <>
            <div
                className={url ? 'img-container' : 'img-container-no-img'}
                {...props}
            >
                {url ? (
                    <>
                        <img
                            className='img-contain'
                            src={`${BASE_URL}${url}`}
                            alt=''
                        />
                    </>
                ) : (
                    <span className='no-value-column'>{'<<Отсутствует>>'}</span>
                )}
            </div>
            {children}
        </>
    );
};
