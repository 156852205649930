import React, {
    FunctionComponent,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import {
    ImageInput,
    ImageField,
    useNotify,
    FileInput,
    FileInputProps,
    useRecordContext,
} from 'react-admin';
import { CustomImageField } from './CustomImageField';
import { CustomUrlField } from './CustomUrlField';
import { CustomVideoField } from './CustomVideoField';
import { useFormContext } from 'react-hook-form';
import { transformFile } from '../utils/transformFiles';

interface Props extends FileInputProps {
    isImage?: boolean;
    isVideo?: boolean;
    label?: string;
    currentUrl?: string;
    noUploadedAlready?: boolean;
    maxSize?: number;
    deleteCb?: React.Dispatch<SetStateAction<boolean>>;
    returnState?: boolean;
}

export const CustomFileInput: FunctionComponent<Props> = ({
    isImage,
    isVideo,
    maxSize,
    noUploadedAlready = false,
    currentUrl = 'url',
    returnState,
    label = '',
    className,
    deleteCb = null,
    ...props
}) => {
    const [withFileDownload, setWithFileDownload] = useState(false);
    const [fileUploaded, setFileUploaded] = useState('');
    const notify = useNotify();
    const record = useRecordContext();
    const { setValue } = useFormContext();

    useEffect(() => {
        if (deleteCb) {
            if (withFileDownload) {
                deleteCb(false);
            } else {
                deleteCb(true);
            }
        }
    }, [deleteCb, withFileDownload]);

    if (isImage) {
        return (
            <>
                <ImageInput
                    {...props}
                    label={label}
                    onRemove={() => {
                        if (deleteCb || returnState) {
                            setWithFileDownload(false);
                        }
                    }}
                    options={{
                        maxFiles: 12,
                        maxSize,

                        onDrop(acceptedFiles, fileRejections, event) {
                            if (acceptedFiles) {
                                const transformedFiles =
                                    acceptedFiles.map(transformFile);

                                if (noUploadedAlready && props.multiple) {
                                    setValue('files', [...transformedFiles]);
                                }
                                setWithFileDownload(true);
                            }
                        },

                        onDropRejected: (fileRejections: any[], event: any) => {
                            fileRejections[0].errors[0].message = `Скорее всего, файл слишком большой. ${fileRejections[0].errors[0].message}`;
                            notify(fileRejections[0].errors[0].message, {
                                type: 'error',
                            });
                        },
                    }}
                >
                    <ImageField
                        source={withFileDownload ? 'src' : ''}
                        title='title'
                    />
                </ImageInput>
                {record && record[currentUrl] && !withFileDownload ? (
                    <>
                        {'Текущее фото:'}
                        <CustomImageField
                            source={currentUrl}
                            width='150px'
                            height='100px'
                        />
                    </>
                ) : (
                    ''
                )}
            </>
        );
    } else if (isVideo) {
        return (
            <FileInput
                {...props}
                label={label}
                onRemove={() => {
                    if (deleteCb) {
                        setWithFileDownload(false);
                    }
                }}
                options={{
                    maxFiles: 12,
                    maxSize,
                    onDrop(acceptedFiles, fileRejections, event) {
                        if (acceptedFiles && deleteCb) {
                            setWithFileDownload(true);
                        }
                    },
                    onDropRejected: (fileRejections: any[], event: any) => {
                        fileRejections[0].errors[0].message = `Скорее всего, файл слишком большой. ${fileRejections[0].errors[0].message}`;
                        notify(fileRejections[0].errors[0].message, {
                            type: 'error',
                        });
                    },
                }}
            >
                <CustomVideoField
                    source={'src'}
                    withoutHeading
                    label={'Видео'}
                />
            </FileInput>
        );
    }
    return (
        <>
            <FileInput
                {...props}
                label={label}
                onRemove={() => {
                    if (deleteCb || returnState) {
                        setWithFileDownload(false);
                    }
                }}
                options={{
                    maxFiles: 12,
                    maxSize,
                    onDrop(acceptedFiles, fileRejections, event) {
                        if (acceptedFiles) {
                            setWithFileDownload(true);
                            setFileUploaded(acceptedFiles[0].name);
                        }
                    },
                    onDropRejected: (fileRejections: any[], event: any) => {
                        fileRejections[0].errors[0].message = `Скорее всего, файл слишком большой. ${fileRejections[0].errors[0].message}`;
                        notify(fileRejections[0].errors[0].message, {
                            type: 'error',
                        });
                    },
                }}
            >
                <CustomUrlField source={'src'} valueString={fileUploaded} />
            </FileInput>
            {record && record.url && !withFileDownload ? (
                <>
                    <CustomUrlField
                        source={'url'}
                        valueString={'Открыть текущий файл'}
                    />
                </>
            ) : (
                ''
            )}
        </>
    );
};
