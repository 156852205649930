import { required, maxLength, minValue, minLength } from 'react-admin';

export const validateTitle = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minLength(3),
];
export const validateComment = [
    maxLength(150, 'Максимум 150 символов'),
    minLength(3),
];
