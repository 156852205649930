import {
    required,
    maxLength,

    minLength,
} from 'react-admin';

export const validateClient = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minLength(2, 'Минимум 2 символа'),
];

export const validatePhone = [required()];


