import { ITransformedFile } from "../types/base.types";

export const transformFile = (file: File) => {
    if (!(file instanceof File)) {
        return file;
    }

    const preview = URL.createObjectURL(file);
    const transformedFile: ITransformedFile = {
        rawFile: file,
        src: preview,
        title: file.name,
    };

    return transformedFile;
};

// export const transformFiles = (files: any[]) => {
//     if (!files) {
//         return multiple ? [] : null;
//     }

//     if (Array.isArray(files)) {
//         return files.map(transformFile);
//     }

//     return transformFile(files);
// };
