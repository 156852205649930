import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    SimpleForm,
    TextInput,
    Edit,
    BooleanInput,
    DateTimeInput,
    maxLength,
    maxValue,
    NumberInput,
} from 'react-admin';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateClient, validatePhone } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const AppformUpdate: FC<Props> = () => {

    const transform = (data: any) => {
        return {
            ...data,
        };
    };

    return (
        <Edit
            actions={<EditingTopToolbar />}
            title={'Редактировать заявку'}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='client'
                    label='Имя'
                    fullWidth
                    validate={validateClient}
                    helperText='Не более 100 символов.'
                />
                <TextInput
                    source='phone'
                    label='Телефон'
                    fullWidth
                    validate={validatePhone}
                />
                <DateTimeInput
                    source='reserveDate'
                    label='Дата и время резерва'
                />
                <NumberInput
                    source='guests'
                    label='Кол-во гостей'
                    validate={maxValue(100, 'Максимум 100 гостей')}
                />
                <TextInput
                    source='wishes'
                    label='Пожелания'
                    fullWidth
                    validate={maxLength(300, 'Максимум 300 символов')}
                    helperText='Не более 300 символов.'
                />
                <TextInput
                    source='comment'
                    label='Комментарий'
                    fullWidth
                    validate={maxLength(300, 'Максимум 300 символов')}
                    helperText='Не более 300 символов.'
                />
                <BooleanInput source='purchased' label='Куплено' />
                <BooleanInput source='phoned' label='Связались' />
            </SimpleForm>
        </Edit>
    );
};
