import { DataProvider, fetchUtils, Options } from 'react-admin';
import { stringify } from 'query-string';
import { determineDataContentType } from '../utils/determineDataContentType';
import { API_URL } from '../utils/const';

const httpClient = (url: string, options: Options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = localStorage.getItem('auth');
    if (token) {
        options.user = { token, authenticated: true };
    }

    return fetchUtils.fetchJson(url, options);
};

export const dataProvider: DataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const query = {
            sort: JSON.stringify(params.sort),
            range: JSON.stringify({
                start: (page - 1) * perPage,
                end: page * perPage - 1,
            }),
            filter: JSON.stringify(params.filter),
        };
        const url = `${API_URL}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            const contentRange = headers.get('content-range')?.split('/').pop();
            return {
                data: json,
                total: parseInt(contentRange ? contentRange : '0', 10),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${API_URL}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${API_URL}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const query = {
            sort: JSON.stringify(params.sort),
            range: JSON.stringify({
                start: (page - 1) * perPage,
                end: page * perPage - 1,
            }),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${API_URL}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            const contentRange: string | undefined = headers
                .get('content-range')
                ?.split('/')
                .pop();
            return {
                data: json,
                total: parseInt(contentRange ? contentRange : '0', 10),
            };
        });
    },

    update: (resource, params) => {
        console.log('Данные для обновления', params.data);
        return httpClient(`${API_URL}/${resource}/${params.id}`, {
            method: 'PUT',
            body: determineDataContentType(params.data),
        }).then(({ json }) => {
            console.log('Данные после обновления', json);
            return {
                data: { ...json },
            };
        });
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${API_URL}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
   
        return httpClient(`${API_URL}/${resource}`, {
            method: 'POST',
            body: determineDataContentType(params.data),
        }).then(({ json }) => {
            console.log('Данные после создания', json);
            return {
                data: Array.isArray(json) ? [...json] : { ...json },
            };
        });
    },

    delete: (resource, params) =>
        httpClient(`${API_URL}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json, body }) => ({ data: json || body })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${API_URL}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
        }).then(({ json, body }) => {
            return { data: json || body };
        });
    },
};
