import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    TextField,
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    RichTextField,
    BooleanField,
    useRecordContext,
    useShowController,
    TextFieldProps,
} from 'react-admin';
import { CustomTextField } from '../CustomTextField';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { ParagraphTypeField } from './ParagraphsList';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ParagraphsValues: FC<TextFieldProps> = () => {
    const record = useRecordContext();
    return (
        <ul>
            {record.values.map((v: string) => (
                <li key={v}>{v}</li>
            ))}
        </ul>
    );
};

export const ParagraphsShow: FC<Props> = () => {
    const { record } = useShowController();
    console.log(record);
    return (
        <Show actions={<EditingTopToolbar withEdit />}>
            <SimpleShowLayout>
                <ReferenceField
                    label='Страницы'
                    source='category.id'
                    reference='categories'
                />
                <TextField source='id' />
                <TextField source='slug' label='Именной идентификатор' />
                <ParagraphTypeField source='type' label='Тип параграфа' />
                <CustomTextField source='title' label='Заголовок' />
                <ParagraphsValues label='Параграфы' />
                {record && record.showUntilDate ? (
                    <DateField
                        variant='h6'
                        showTime
                        source='showUntilDate'
                        label='Показывать до:'
                    />
                ) : (
                    ''
                )}
                <BooleanField source='redirect' label='Редирект' />
                {record && record.redirect ? (
                    <TextField source='redirectPath' label='Ссылка' />
                ) : (
                    ''
                )}
                {record && record.redirect ? (
                    <TextField
                        source='redirectName'
                        label='Название перехода по ссылке'
                    />
                ) : (
                    ''
                )}

                <BooleanField source='active' label='Активность' />

                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <DateField
                    showTime
                    source='createdDate'
                    label='Дата создания'
                />
            </SimpleShowLayout>
        </Show>
    );
};
