import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    TextField,
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    BooleanField,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const PhotoShow: FC<Props> = () => {
    return (
        <Show actions={<EditingTopToolbar withEdit />}>
            <SimpleShowLayout>
                <TextField source='id' />
                <BooleanField source='intro' label='Для интро' />
                <BooleanField source='collage' label='Для коллажа' />
                <ReferenceField
                    label='Категория'
                    source='category.id'
                    reference='categories'
                />
                <CustomImageField
                    source='url'
                    label={'Фото'}
                    width='400px'
                    height='300px'
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <DateField
                    showTime
                    source='createdDate'
                    label='Дата создания'
                />
            </SimpleShowLayout>
        </Show>
    );
};
