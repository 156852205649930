import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField,
    RichTextField,
    SimpleShowLayout,
    NumberField,
} from 'react-admin';
import { CustomListActions } from '../CustomListActions';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ExpandLayout = () => (
    <SimpleShowLayout>
        <RichTextField source='wishes' label='Пожелания гостя' />
        <TextField source='comment' label='Комментарий' />
        <BooleanField source='purchased' label='Купил' />
        <BooleanField source='phoned' label='Связались' />
        <DateField showTime source='updatedDate' label='Обновление' />
        <DateField showTime source='createdDate' label='Создание' />
    </SimpleShowLayout>
);

export const AppformList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid
                size='small'
                optimized
                rowClick={'expand'}
                expand={<ExpandLayout />}
            >
                <TextField source='id' />
                <TextField source='client' label='Имя' />
                <TextField source='phone' label='Телефон' />
                <DateField
                    showTime
                    source='reserveDate'
                    label='Дата резерва'
                    locales='ru-RU'
              
                />

                <NumberField source='guests' label='Кол-во гостей' />
                <EditButton label='РЕД.' />
            </Datagrid>
        </List>
    );
};
