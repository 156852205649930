import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    maxLength,
    NumberInput,
    number,
    minValue,
    maxValue,
    useNotify,
    useRedirect,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';
import { FILE_IMG_MAX_SIZE, SINGLE_FILE_FIELD_NAME } from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const validateTitle = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minValue(3),
];
const validatePrice = [required(), number(), minValue(0), maxValue(1000000)];

export const MenuItemCreate: FC<Props> = () => {
    const transform = (data: any) => ({
        ...data,
        price: data.price.toString(),
    });

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Создать позицию в меню'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='title'
                    label='Название'
                    fullWidth
                    validate={validateTitle}
                />
                <NumberInput
                    source='price'
                    label='Цена'
                    fullWidth
                    validate={validatePrice}
                />
                <ReferenceInput
                    source='categoryId'
                    reference='categories'
                    filter={{ purpose: ContentPurposeEnum.MENU }}
                >
                    <SelectInput
                        validate={required()}
                        label='Категория'
                        fullWidth
                    />
                </ReferenceInput>
                <CustomFileInput
                    validate={required()}
                    isImage
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Фото'
                    multiple={false}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Обязательное поле. Файлы более 5мб не принимаются. Загрузить можно только один файл.'
                    maxSize={FILE_IMG_MAX_SIZE}
                />
            </SimpleForm>
        </Create>
    );
};
