import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    PasswordInput,
    CheckboxGroupInput,
} from 'react-admin';
import {
    RoleChoices,
} from '../../utils/const';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateEmail, validateName, validatePassword } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const UserCreate: FC<Props> = () => {
    const transform = (data: any) => {
        console.log(data);
        return {
            ...data,
            roles: data.roles || [],
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Создать Админа'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='name'
                    label='Имя'
                    fullWidth
                    validate={validateName}
                    helperText='Не более 100 символов.'
                />
                <TextInput
                    source='email'
                    label='Почта'
                    fullWidth
                    validate={validateEmail}
                    helperText='Не более 80 символов.'
                />
                <PasswordInput
                    fullWidth
                    source='password'
                    validate={validatePassword}
                />
                <CheckboxGroupInput
                    label='Роли'
                    source='roles'
                    choices={[...RoleChoices]}
                    labelPlacement='bottom'
                />
            </SimpleForm>
        </Create>
    );
};
