import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    ListActions,
    BooleanField,
    EditButton,
    useRecordContext,
    TextFieldProps,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';
import { ContentPurposesTranslated } from '../../utils/const';
import { CustomListActions } from '../CustomListActions';

interface CustomTextFieldProps extends TextFieldProps {
    source: string;
}

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const CustomPurposeField: FC<CustomTextFieldProps> = (props) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <span>
            {
                ContentPurposesTranslated[
                    record[props.source] as ContentPurposeEnum
                ]
            }
        </span>
    );
};

export const CategoryList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'name', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid>
                <TextField source='id' />
                <TextField source='name' label='Название' />
                <TextField source='slug' label='Именной идентификатор' />
                <CustomPurposeField source='purpose' label='Тип контента' />
                <BooleanField source='active' label='Активность' />
                <EditButton label='Ред.' />
            </Datagrid>
        </List>
    );
};
