import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    maxLength,
    maxValue,
    useNotify,
    useRedirect,
    NumberInput,
    DateTimeInput,
    minValue,
} from 'react-admin';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateClient, validatePhone } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const AppformCreate: FC<Props> = () => {
    const transform = (data: any) => {
  
        return {
            ...data,
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Создать заявку'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='client'
                    label='Имя'
                    fullWidth
                    validate={validateClient}
                    helperText='Не более 100 символов.'
                />
                <TextInput
                    source='phone'
                    label='Телефон'
                    fullWidth
                    validate={validatePhone}
                />
                <DateTimeInput
                    validate={minValue(new Date())}
                    source='reserveDate'
                    label='Дата и время резерва'
                />
                <NumberInput
                    source='guests'
                    label='Кол-во гостей'
                    validate={maxValue(100, 'Максимум 100 гостей')}
                />
                <TextInput
                    source='wishes'
                    label='Пожелания'
                    fullWidth
                    validate={maxLength(300, 'Максимум 300 символов')}
                    helperText='Не более 300 символов.'
                />
            </SimpleForm>
        </Create>
    );
};
