import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    ReferenceField,
    ShowButton,
    TextFieldProps,
    useRecordContext,
    BooleanField,
} from 'react-admin';
import { ContentPurposeEnum, ParagraphTypeEnum } from '../../types/base.types';
import { ParagraphTypesTranslated } from '../../utils/const';
import { CustomImageField } from '../CustomImageField';
import { CustomListActions } from '../CustomListActions';
import { CustomTextField } from '../CustomTextField';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ParagraphTypeField: FC<TextFieldProps> = (props) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <span>
            {
                ParagraphTypesTranslated[
                    record[props.source as string] as ParagraphTypeEnum
                ]
            }
        </span>
    );
};

export const ParagraphsList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid rowClick={'show'}>
                <ReferenceField
                    label='Страницы'
                    source='category.id'
                    reference='categories'
                />
                <TextField source='id' />
                <TextField source='slug' label='Именной Id' />
                <ParagraphTypeField source='type' label='Тип' />
                <CustomTextField source='title' label='Заголовок' />
                <BooleanField source='redirect' label='Редирект' />
                <BooleanField source='active' label='Активность' />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <EditButton label='РЕД.' />
            </Datagrid>
        </List>
    );
};
