import { IBasicRequest } from '../types/base.types';

export const getBasicRequest = ({
    url,
    method = 'GET',
    body = null,
    headers = {},
}: IBasicRequest) => {
    return new Request(url, {
        method: method,
        body: body && JSON.stringify(body),
        credentials: 'include',
        headers: new Headers({
            'Content-Type': 'application/json',
            ...headers,
        }),
    });
};
