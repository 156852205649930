import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    TextField,
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    BooleanField,
    RichTextField,
    useReference,
    TextFieldProps,
    useRecordContext,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';

import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ParagraphsValues: FC<TextFieldProps> = () => {
    const record = useRecordContext();
    return (
        <ul>
            {record.desc.map((v: string) => (
                <li key={v}>{v}</li>
            ))}
        </ul>
    );
};

export const ProductShow: FC<Props> = () => {
    const { referenceRecord } = useReference({
        id: 3,
        reference: 'gallery/videos',
    });
    console.log(referenceRecord);
    return (
        <Show actions={<EditingTopToolbar withEdit />}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='title' label='Название' />
                <TextField
                    source='shortDesc'
                    label='Описание'
                    emptyText='---'
                />
                <ParagraphsValues />
                
                <ReferenceField
                    label='Видео'
                    source='video.id'
                    reference='gallery/videos'
                    emptyText='---'
                />

                <CustomImageField
                    showAll
                    source='photos'
                    label='Фото'
                    width='150px'
                    height='100px'
                />
                <TextField source='path' label='Путь (slug)' />
                <DateField
                    showTime
                    source='startDate'
                    label='Когда начинается'
                    locales='ru-RU'
                />
                <DateField
                    showTime
                    source='endDate'
                    label='Когда заканчивается'
                    locales='ru-RU'
                    emptyText='---'
                />
                <BooleanField source='active' label='Активность' />

                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <DateField
                    showTime
                    source='createdDate'
                    label='Дата создания'
                />
            </SimpleShowLayout>
        </Show>
    );
};
