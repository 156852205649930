import { RichTextInput } from 'ra-input-rich-text';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRedirect,
    BooleanInput,
    DateTimeInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import {
    FILE_IMG_MAX_SIZE,
    MULTIPLE_FILES_FIELD_NAME,
} from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateDesc, validatePath, validateShort, validateTitle } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ProductCreate: FC<Props> = () => {
    const transform = (data: any) => {

        const videoId = data.video.id;
        delete data.video;
        const desc = data.desc.map((d: any) => d.value);
        delete data.desc;
        return {
            ...data,
            desc,
            videoId,
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Создать услугу'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='title'
                    label='Название услуги'
                    fullWidth
                    validate={validateTitle}
                    helperText='Не менее 3 символа и не более 100 символов.'
                />
                <TextInput
                    source='shortDesc'
                    label='Описание'
                    fullWidth
                    validate={validateShort}
                    helperText='Не более 150 символов.'
                />

                <CustomFileInput
                    isImage
                    validate={required('Загрузите хотя бы одно фото')}
                    source={MULTIPLE_FILES_FIELD_NAME}
                    label='Фото'
                    multiple={true}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Файлы более 5мб не принимаются. Загрузить можно до 10 изображений.'
                    maxSize={FILE_IMG_MAX_SIZE}
                />

                <ReferenceInput source='video.id' reference='gallery/videos'>
                    <SelectInput
                        label='Видео (опционально)'
                        optionText='title'
                        fullWidth
                        helperText={''}
                    />
                </ReferenceInput>

                <ArrayInput
                    source='desc'
                    label='Подробное описание (Нужно, если подключен динамический путь)'
                    className='array-input'
                    fullWidth
                    helperText='Параграфы переносятся на отдельную строку на сайте. Максимум 5 параграфов. Не более 1000 символов в каждом.'
                >
                    <SimpleFormIterator
                        fullWidth
                        inline
                        className='iterator'
                        getItemLabel={(index) => `${index + 1}.`}
                    >
                        <RichTextInput
                            style={{ color: 'black' }}
                            source='value'
                            fullWidth
                            validate={validateDesc}
                            className='rich-text'
                            label={false}
                            toolbar={<span className='rich-text' />}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                    source='path'
                    label='Динамический путь (опционально)'
                    fullWidth
                    validate={validatePath}
                    helperText='Если указан, то на сайте будет создан переход на отдельную страницу. '
                />

                <DateTimeInput
                    source='startDate'
                    label='Когда начинается (опционально)'
                    helperText='Не более 1200 символов.'
                />
                <DateTimeInput
                    source='endDate'
                    label='Когда заканчивается (опционально)'
                    helperText='Если указать, то на сайте будет скрыта услуга по истечении срока'
                />
                <BooleanInput
                    source='active'
                    label='Активность'
                    helperText='Отключает отображение на сайте в любом случае.'
                    fullWidth
                    defaultValue={true}
                />
            </SimpleForm>
        </Create>
    );
};
