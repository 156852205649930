import { required, maxLength, minValue } from 'react-admin';

export const validateName = [
    required(),
    maxLength(80, 'Максимум 80 символов'),
    minValue(3),
];
export const validateDesc = [
    required(),
    maxLength(300, 'Максимум 300 символов'),
    minValue(3),
];

export const validateSlogan = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minValue(3),
];
