import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    useRecordContext,
    TextFieldProps,
} from 'react-admin';
import { RoleEnum } from '../../types/base.types';
import { RolesTranslated } from '../../utils/const';
import { CustomListActions } from '../CustomListActions';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const RolesField: FC<TextFieldProps> = () => {
    const record = useRecordContext();

    return (
        <ul>
            {record.roles.map((role: RoleEnum) => (
                <li key={role}>{RolesTranslated[role]}</li>
            ))}
        </ul>
    );
};

export const UserList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid rowClick={'edit'} size='small' optimized>
                <TextField source='id' />
                <TextField source='name' label='Имя' />
                <TextField source='email' label='Почта' />
                <RolesField label='Роли' />
                <DateField showTime source='updatedDate' label='Обновление' />
                <DateField showTime source='createdDate' label='Создание' />
                <EditButton label='РЕД.' />
            </Datagrid>
        </List>
    );
};
