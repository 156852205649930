import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    ReferenceInput,
    required,
    SelectInput,
} from 'react-admin';
import { FILE_ICON_MAX_SIZE, SINGLE_FILE_FIELD_NAME } from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validatePrice, validateText } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ProductPlanCreate: FC<Props> = () => {
    const transform = (data: any) => {
        console.log(data);
        return {
            ...data,
            file: data.file || {},
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Создать ценовой тариф'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <ReferenceInput source='variantId' reference='product-variants'>
                    <SelectInput
                        validate={required()}
                        label='Вариант продаж'
                        optionText='title'
                        fullWidth
                        helperText={
                            'Ценовое предложение должно прикрепляться к одному из вариантов продаж'
                        }
                    />
                </ReferenceInput>

                <TextInput
                    source='desc'
                    label='Описание'
                    fullWidth
                    validate={validateText}
                />

                <TextInput
                    source='price'
                    label='Цена'
                    fullWidth
                    validate={validatePrice}
                />
                <TextInput
                    source='comment'
                    label='Пояснения'
                    fullWidth
                    validate={validateText}
                />
                <CustomFileInput
                    isImage
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Иконка'
                    multiple={false}
                    placeholder='Перетащите иконку или кликните для загрузки'
                    accept={['image/svg', 'image/webp', 'image/png']}
                    helperText='Файлы более 2мб не принимаются. Загрузить можно 1 иконку. Форматы: svg, png, webp'
                    maxSize={FILE_ICON_MAX_SIZE}
                />
            </SimpleForm>
        </Create>
    );
};
