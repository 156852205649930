import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    maxLength,
    minValue,
    BooleanInput,
    minLength,
    regex,
    useNotify,
    useRedirect,
    Button,
    TopToolbar,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';
import {
    ContentPurposeChoices,
    ContentPurposesTranslated,
    SLUG_REGEX,
} from '../../utils/const';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const validateName = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minLength(3),
];
const validateSlug = [
    required(),
    minLength(1),
    maxLength(14),
    regex(SLUG_REGEX, 'Только английские буквы'),
];

export const CategoryCreate: FC<Props> = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    return (
        <Create
            actions={<EditingTopToolbar withReset />}
            title={'Создать категорию'}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='name'
                    label='Название'
                    validate={validateName}
                    fullWidth
                    helperText='До 32 символов. Отображается для клиента'
                />
                <TextInput
                    source='slug'
                    label='Именной идентификатор'
                    validate={validateSlug}
                    fullWidth
                    helperText='Максимум 14 английских букв или цифр. Уникален для каждого типа контента'
                />
                <SelectInput
                    fullWidth
                    source='purpose'
                    label='Тип контента'
                    validate={required()}
                    choices={ContentPurposeChoices}
                />
                <BooleanInput
                    fullWidth
                    source='active'
                    label='Активность'
                    defaultValue={true}
                />
            </SimpleForm>
        </Create>
    );
};
