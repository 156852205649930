import {
    DetailedHTMLProps,
    FC,
    HTMLAttributes,
    useEffect,
    useState,
} from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    maxLength,
    BooleanInput,
    minLength,
    Edit,
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    useGetOne,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateShort } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const validateTitle = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minLength(3),
];

export const ProductVariantUpdate: FC<Props> = () => {
    const { id } = useParams();
    const { data } = useGetOne('product-variants', {
        id,
    });

    const [newRecord, setNewRecord] = useState<any>({});
    const transform = (data: any) => {
        const additions = data.additions.map((a: any) => a.value);
        const limits = data.limits.map((l: any) => l.value);
        delete data.limits;
        delete data.additions;
        return {
            ...data,
            productId: data.item.id,
            additions,
            limits,
        };
    };

    useEffect(() => {
        if (data) {
            setNewRecord({
                ...data,
                additions: data.additions.map((a: string) => ({ value: a })),
                limits: data.limits.map((l: string) => ({ value: l })),
            });
        }
    }, [data]);

    return (
        <Edit
            title={'Внести изменения в вариант продаж'}
            actions={<EditingTopToolbar />}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
                record={newRecord}
            >
                <ReferenceInput source='item.id' reference='products'>
                    <SelectInput
                        validate={required()}
                        label='Услуга'
                        optionText='title'
                        fullWidth
                        helperText={
                            'Каждый вариант продаж должен быть прикреплен к определенной услуге. Например, услуга платного бронирования имеет два варианта: "Дневное" и "Стандартное"'
                        }
                    />
                </ReferenceInput>
                <TextInput
                    source='title'
                    label='Название'
                    validate={validateTitle}
                    fullWidth
                    helperText='До 100 символов. Уникальное'
                />

                <TextInput
                    source='shortDesc'
                    label='Описание (опционально)'
                    validate={validateShort}
                    fullWidth
                    helperText='До 300 символов. Минимум 3'
                />

                <ArrayInput source='additions' label='Преимущества'>
                    <SimpleFormIterator fullWidth inline>
                        <TextInput
                            fullWidth
                            source='value'
                            label='Введите преимущество'
                            helperText={false}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source='limits' label='Ограничения'>
                    <SimpleFormIterator fullWidth>
                        <TextInput
                            fullWidth
                            source='value'
                            label='Введите ограничение'
                            helperText={false}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput fullWidth source='payment' label='С оплатой' />
                <BooleanInput fullWidth source='active' label='Активность' />
            </SimpleForm>
        </Edit>
    );
};
