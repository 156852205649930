import { FC } from 'react';
import { TextFieldProps, useRecordContext } from 'react-admin';

interface Props extends TextFieldProps {
    source: string;
}

export const CustomTextField: FC<Props> = (props) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <span className={record[props.source] ? '' : 'no-value-column'}>
            {record[props.source] || '<<Отсутствует>>'}
        </span>
    );
};
