import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    maxLength,
    BooleanInput,
    minLength,
    useNotify,
    useRedirect,
    ReferenceInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateShort } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const validateTitle = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minLength(3),
];

export const ProductVariantCreate: FC<Props> = () => {
    const redirect = useRedirect();
    const notify = useNotify();
    const transform = (data: any) => {
        const additions = data.additions.map((a: any) => a.value);
        const limits = data.limits.map((l: any) => l.value);
        delete data.limits;
        delete data.additions;
        return {
            ...data,
            additions,
            limits,
        };
    };

    return (
        <Create
            actions={<EditingTopToolbar withReset />}
            title={'Создать вариант продаж'}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <ReferenceInput source='productId' reference='products'>
                    <SelectInput
                        validate={required()}
                        label='Услуга'
                        optionText='title'
                        fullWidth
                        helperText={
                            'Каждый вариант продаж должен быть прикреплен к определенной услуге. Например, услуга платного бронирования имеет два варианта: "Дневное" и "Стандартное"'
                        }
                    />
                </ReferenceInput>
                <TextInput
                    source='title'
                    label='Название'
                    validate={validateTitle}
                    fullWidth
                    helperText='До 100 символов. Уникальное'
                />

                <TextInput
                    source='shortDesc'
                    label='Описание (опционально)'
                    validate={validateShort}
                    fullWidth
                    helperText='До 300 символов. Минимум 3'
                />

                <ArrayInput source='additions' label='Преимущества'>
                    <SimpleFormIterator fullWidth>
                        <TextInput
                            fullWidth
                            source='value'
                            label='Введите преимущество'
                            helperText={false}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source='limits' label='Ограничения'>
                    <SimpleFormIterator fullWidth>
                        <TextInput
                            fullWidth
                            source='value'
                            label='Введите ограничение'
                            helperText={false}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput
                    fullWidth
                    source='payment'
                    label='С оплатой'
                    defaultValue={false}
                />
                <BooleanInput
                    fullWidth
                    source='active'
                    label='Активность'
                    defaultValue={true}
                />
            </SimpleForm>
        </Create>
    );
};
