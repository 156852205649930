import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    ListActions,
    EditButton,
    ReferenceField,
    ShowButton,
} from 'react-admin';
import { BASE_URL } from '../../utils/const';
import { CustomExpandText } from '../CustomExpandText';
import { CustomImageField } from '../CustomImageField';
import { CustomListActions } from '../CustomListActions';
import { CustomTextField } from '../CustomTextField';
import { CustomUrlField } from '../CustomUrlField';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const DocList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid>
                <TextField source='id' />
                <TextField source='title' label='Название документа' />
                <CustomTextField source='comment' label='Комментарий' />
                <ReferenceField
                    label='Категория'
                    source='category.id'
                    reference='categories'
                />
                <CustomUrlField
                    source='url'
                    base={BASE_URL}
                    label={'Файл'}
                    valueString='Открыть'
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <EditButton label='РЕД.' />
            </Datagrid>
        </List>
    );
};
