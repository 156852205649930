import { TopToolbar, CreateButton, ExportButton, DeleteButton, Toolbar } from "react-admin";
import { CustomSaveButton } from "./CustomSaveButton";

export const EditingToolbar = () => (
    <Toolbar className="editing-toolbar">
        <CustomSaveButton />
        <DeleteButton label="Удалить"/>
    </Toolbar>
);

