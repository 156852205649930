import { RichTextInput } from 'ra-input-rich-text';
import {
    DetailedHTMLProps,
    FC,
    HTMLAttributes,
    useEffect,
    useState,
} from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Edit,
    BooleanInput,
    useEditController,
    DateTimeInput,
    ArrayInput,
    SimpleFormIterator,
    useGetOne,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { ContentPurposeEnum } from '../../types/base.types';
import {
    FILE_IMG_MAX_SIZE,
    ParagraphTypeChoices,
    SINGLE_FILE_FIELD_NAME,
} from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateSlug, validateTitle, validateValue } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ParagraphsUpdate: FC<Props> = () => {
    const { id } = useParams();
    const { data } = useGetOne('paragraphs', {
        id,
    });
    const [isRedirectOn, setRedirectOn] = useState(false);
    const [newRecord, setNewRecord] = useState<any>({});

    const transform = (data: any) => {
        const values = data.values.map((v: any) => v.value);
        delete data.values;
        return {
            ...data,
            values,
            showUntilDate: data.showUntilDate || '',
            categoryId: data.category.id,
            redirectPath: isRedirectOn ? data.redirectPath : undefined,
            redirectName: isRedirectOn ? data.redirectName : undefined,
        };
    };

    useEffect(() => {
        if (data) {
            setNewRecord({
                ...data,
                values: data.values.map((v: string) => ({ value: v })),
            });
            setRedirectOn(data.redirect);
        }
    }, [data]);

    const onRedirectInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRedirectOn(!isRedirectOn);
    };

    return (
        <Edit
            title={'Редактировать вопрос'}
            actions={<EditingTopToolbar />}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
                record={newRecord}
            >
                <ReferenceInput
                    source='category.id'
                    reference='categories'
                    filter={{ purpose: ContentPurposeEnum.PAGES }}
                >
                    <SelectInput
                        validate={required()}
                        label='Категория страницы'
                        fullWidth
                        helperText='Выберите страницу, на которой будет отображаться параграф'
                    />
                </ReferenceInput>
                <SelectInput
                    fullWidth
                    source='type'
                    label='Тип параграфа'
                    validate={required()}
                    choices={ParagraphTypeChoices}
                />

                <ArrayInput
                    source='values'
                    label='Параграфы'
                    validate={required()}
                    className='array-input'
                    fullWidth
                    helperText='Параграфы переносятся на отдельную строку на сайте. Максимум 5 параграфов. Не более 1000 символов в каждом.'
                >
                    <SimpleFormIterator
                        fullWidth
                        inline
                        className='iterator'
                        getItemLabel={(index) => `${index + 1}.`}
                    >
                        <RichTextInput
                            style={{ color: 'black' }}
                            source='value'
                            fullWidth
                            validate={validateValue}
                            className='rich-text'
                            label={false}
                            toolbar={<span className='rich-text' />}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <TextInput
                    source='slug'
                    label='Именной идентификатор'
                    validate={validateSlug}
                    fullWidth
                    helperText='Максимум 14 английских букв или цифр. Нужно для фронтенда. Значение захардкодено.'
                />
                <TextInput
                    source='title'
                    label='Заголовок (опционально)'
                    fullWidth
                    validate={validateTitle}
                />

                <BooleanInput
                    onChange={onRedirectInputChange}
                    fullWidth
                    source='redirect'
                    label='Редирект'
                />

                <TextInput
                    source='redirectPath'
                    label='Ссылка для редиректа'
                    fullWidth
                    disabled={!isRedirectOn}
                />
                <TextInput
                    source='redirectName'
                    label='Название перехода по ссылке'
                    fullWidth
                    disabled={!isRedirectOn}
                />
                <DateTimeInput
                    source='showUntilDate'
                    label='Показывать до (опционально)'
                />
                <BooleanInput
                    fullWidth
                    source='active'
                    label='Активность'
                    defaultValue={true}
                />
            </SimpleForm>
        </Edit>
    );
};
