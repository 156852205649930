import {
    maxLength,
    minLength,
} from 'react-admin';


export const validateShort = [
    maxLength(300, 'Максимум 300 символов'),
    minLength(3, 'Минимум 3 символа'),
];
