import { AuthProvider } from 'react-admin';
import { AUTH_URL, TOKEN_EXP_GAP } from '../utils/const';
import { getBasicRequest } from '../utils/getBasicRequest';
import { setTokenToStorage } from '../utils/setTokenToStorage';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { refreshToken } from '../utils/refreshToken';

export const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        const request = getBasicRequest({
            url: `${AUTH_URL}/login`,
            method: 'POST',
            body: { email: username, password },
        });

        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                setTokenToStorage(response);

                return response.json();
            })
            .then((userData) => {
                localStorage.setItem('user', JSON.stringify(userData));
            })
            .catch((e) => {
                throw new Error(e.message);
            });
    },

    logout: async () => {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('auth');

        const request = getBasicRequest({
            url: `${AUTH_URL}/logout`,
            method: 'POST',
            headers: {
                Authorization: token ? token : '',
            },
        });

        try {
            await fetch(request);
            return Promise.resolve();
        } catch {
            return Promise.reject();
        } finally {
            if (token) {
                localStorage.removeItem('auth');
            }
            if (user) {
                localStorage.removeItem('user');
            }
        }
    },

    checkAuth: async () => {
        const user = localStorage.getItem('user');
        const token = localStorage.getItem('auth');
        if (!user || !token) {
            Promise.reject();
        }
        if (token) {
            const { exp } = jwt_decode<JwtPayload>(token);
            if (exp && exp * 1000 - TOKEN_EXP_GAP < Date.now()) {
                return await refreshToken();
            }
            return Promise.resolve();
        }
        return Promise.reject();
    },

    checkError: async (error) => {
        const status = error.status;
        if (status === 401) {
            return await refreshToken();
        }
        return Promise.resolve();
    },

    getIdentity: () => {
   
        const userJson = localStorage.getItem('user');
        if (userJson) {
            const user = JSON.parse(userJson);
            console.log(user);
            return Promise.resolve({
                id: user.id,
                fullName: user.name,
            });
        }
        return Promise.reject();
    },
    getPermissions: () => Promise.resolve(''),
};

export default authProvider;
