import { required, maxLength, minLength } from 'react-admin';

export const validateSlogan = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minLength(3, 'Минимум 3 символа'),
];

export const validateComment = [
    maxLength(80, 'Максимум 100 символов'),
    minLength(3, 'Минимум 3 символа'),
];

export const validateDesc = [
    required(),
    maxLength(300, 'Максимум 100 символов'),
    minLength(3, 'Минимум 3 символа'),
];
