import { FC } from 'react';
import {
    EditButton,
    TopToolbar,
    useCreateContext,
    useEditContext,
    useRedirect,
    useShowContext,
} from 'react-admin';
import Button from '@mui/material/Button';

interface Props {
    withEdit?: boolean;
    withReset?: boolean;
}

export const EditingTopToolbar: FC<Props> = ({ withEdit = false }) => {
    const { resource: resourceCreate } = useCreateContext();
    const { resource: resourceEdit } = useEditContext();
    const { resource: resourceShow } = useShowContext();

    const redirect = useRedirect();
    const getBack = () => {
        redirect(`/${resourceCreate || resourceEdit || resourceShow}`);
    };

    return (
        <TopToolbar className='editing-top-toolbar'>
            {withEdit ? (
                <EditButton
                    label='Редактировать'
                    className='top-toolbar-button'
                />
            ) : (
                ''
            )}

            <Button
                color='primary'
                onClick={getBack}
                className='top-toolbar-button'
            >
                Назад
            </Button>
        </TopToolbar>
    );
};
