import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRedirect,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';
import {
    FILE_IMG_MAX_SIZE,
    MULTIPLE_FILES_FIELD_NAME,
} from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const PhotoCreate: FC<Props> = () => {
    const transform = (data: any) => {
        return {
            ...data,
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Добавить фотографии'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <ReferenceInput
                    source='categoryId'
                    reference='categories'
                    filter={{ purpose: ContentPurposeEnum.GALLERY }}
                >
                    <SelectInput
                        validate={required()}
                        label='Категория'
                        fullWidth
                    />
                </ReferenceInput>
                <CustomFileInput
                    validate={required()}
                    isImage
                    source={MULTIPLE_FILES_FIELD_NAME}
                    label='Фото'
                    multiple={true}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Файлы более 5мб не принимаются. Загрузить можно до 12 изображений.'
                    maxSize={FILE_IMG_MAX_SIZE}
                />
            </SimpleForm>
        </Create>
    );
};
