import { FC } from 'react';
import {
    ImageFieldProps,
    TextFieldProps,
    UrlFieldProps,
    useRecordContext,
} from 'react-admin';

interface Props extends UrlFieldProps {
    source: string;
    base?: string;
    valueString?: string;
}

export const CustomUrlField: FC<Props> = (props) => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <>
            {record[props.source] ? (
                <a
                    href={`${props.base || ''}${record[props.source]}`}
                    target='_blank' rel="noreferrer"
                >
                    {props.valueString ? props.valueString : 'Перейти'}
                </a>
            ) : (
                <span className={'no-value-column'}>{'<<Отсутствует>>'}</span>
            )}
        </>
    );
};
