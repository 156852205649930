export enum ContentPurposeEnum {
    GALLERY = 'gallery',
    MENU = 'menu',
    FAQ = 'faq',
    TEAM = 'teammates',
    DOC = 'documents',
    PAGES = 'pages',
}

export enum TeammatesPoisitionEnum {
    CHEF = 'Шеф-повар',
    COOKER = 'Повар',
    WAITER = 'Официант',
    BARMAN = 'Бармен',
    MANAGER = 'Менеджер',
    DIRECTOR = 'Управлящий',
    HOSTES = 'Хостес',
}

export enum ParagraphTypeEnum {
    TEXT = 'text',
    ALERT = 'alert',
    PROMO = 'promo',
    NEW = 'new',
    SOON = 'soon',
}

export interface ContentPurposeTranslatedType
    extends Record<ContentPurposeEnum, string> {}

export interface IBasicRequest {
    url: string;
    method?: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
    body?: any;
    headers?: HeadersInit;
}

export enum RoleEnum {
    ADMIN = 'admin',
    EDITOR = 'editor',
    WATCHER = 'watcher',
}

export interface ITransformedFile {
    rawFile: File;
    src: string;
    title: string;
}
