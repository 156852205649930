import {
    required,
    maxLength,
    minValue,
    email,
    regex,
    minLength,
} from 'react-admin';

export const validateName = [
    required(),
    maxLength(25, 'Максимум 25 символов'),
    minLength(4, 'Минимум 4 символа'),
    regex(/^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/),
];

export const validateEmail = [required(), email()];

export const validatePassword = [
    required(),
    maxLength(50, 'Максимум 50 символов'),
    minLength(6, 'Минимум 6 символов'),
];

export const validateEditPassword = [
    maxLength(50, 'Максимум 50 символов'),
    minLength(6, 'Минимум 6 символов'),
];
