import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    required,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    Edit,
    BooleanInput,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';

import { CustomImageField } from '../CustomImageField';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const PhotoUpdate: FC<Props> = () => {

    const transform = (data: any) => {
        return {
            ...data,
            categoryId: data.category.id,
        };
    };

    return (
        <Edit
            title={'Редактировать фото'}
            actions={<EditingTopToolbar />}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <ReferenceInput
                    source='category.id'
                    reference='categories'
                    filter={{ purpose: ContentPurposeEnum.GALLERY }}
                >
                    <SelectInput
                        validate={required()}
                        label='Категория'
                        fullWidth
                    />
                </ReferenceInput>
                <BooleanInput source='intro' label='Для интро' />
                <BooleanInput source='collage' label='Для коллажа' />
                <CustomImageField source='url' width='500px' height='400px' />
            </SimpleForm>
        </Edit>
    );
};
