import { DetailedHTMLProps, FC, HTMLAttributes, useState } from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Edit,
    BooleanInput,
    useEditController,
} from 'react-admin';
import { FILE_IMG_MAX_SIZE, SINGLE_FILE_FIELD_NAME } from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateSlogan, validateComment, validateDesc } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const MenuDetailsUpdate: FC<Props> = () => {
    const { record } = useEditController();
    const [isDeleteAvailable, setDeleteAvailable] = useState(true);
    const transform = (data: any) => {
        const file = data.file;
        const itemId = Number(data.item.id);
        delete data.file;
        delete data.item;
        return {
            ...data,
            itemId,
            file: file && !data.deletePhoto ? file : {},
        };
    };

    return (
        <Edit
            actions={<EditingTopToolbar />}
            title={'Редактировать топ-позицию в меню'}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <ReferenceInput source='item.id' reference='menu/items'>
                    <SelectInput
                        validate={required()}
                        label='Позиция'
                        fullWidth
                        disabled
                    />
                </ReferenceInput>

                <TextInput
                    source='slogan'
                    label='Слоган'
                    fullWidth
                    validate={validateSlogan}
                    helperText='Не более 100 символов.'
                />
                <TextInput
                    source='comment'
                    label='Комментарий'
                    fullWidth
                    validate={validateComment}
                    helperText='Не более 80 символов.'
                />
                <TextInput
                    source='desc'
                    label='Описание'
                    fullWidth
                    validate={validateDesc}
                    helperText='Не более 300 символов.'
                />

                <BooleanInput source='active' label='Активность' fullWidth />

                <CustomFileInput
                    isImage
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Изменить или добавить фото'
                    multiple={false}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Файлы более 5мб не принимаются. Загрузить можно только один файл.'
                    maxSize={FILE_IMG_MAX_SIZE}
                    deleteCb={setDeleteAvailable}
                />
                {isDeleteAvailable && (
                    <BooleanInput
                        source='deletePhoto'
                        label='Удалить текущее фото'
                        fullWidth
                        defaultValue={false}
                        disabled={record && !record.url}
                        helperText={
                            record && !record.url
                                ? 'Текущее фото отсутствует'
                                : ''
                        }
                    />
                )}
            </SimpleForm>
        </Edit>
    );
};
