import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    TextField,
    DateField,
    Show,
    SimpleShowLayout,
    BooleanField,
} from 'react-admin';
import { BASE_URL } from '../../utils/const';
import { CustomVideoField } from '../CustomVideoField';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const VideoShow: FC<Props> = () => {
    return (
        <Show actions={<EditingTopToolbar withEdit />}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='title' label='Название видео' />
                <BooleanField source='promo' label='Промо' />
                <CustomVideoField
                    withControls
                    source='url'
                    base={BASE_URL}
                    withoutHeading
                    label={'Видео'}
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <DateField
                    showTime
                    source='createdDate'
                    label='Дата создания'
                />
            </SimpleShowLayout>
        </Show>
    );
};
