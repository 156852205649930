import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    SimpleForm,
    TextInput,
    Edit,
    CheckboxGroupInput,
    PasswordInput,
} from 'react-admin';
import {
    RoleChoices,
} from '../../utils/const';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import {
    validateEditPassword,
    validateEmail,
    validateName,
} from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const UserUpdate: FC<Props> = () => {

    const transform = (data: any) => {
        return {
            ...data,
        };
    };

    return (
        <Edit
            actions={<EditingTopToolbar />}
            title={'Редактировать админа'}
            transform={transform}
          
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='name'
                    label='Имя'
                    fullWidth
                    validate={validateName}
                    helperText='Не более 25 символов.'
                />
                <TextInput
                    source='email'
                    label='Почта'
                    fullWidth
                    validate={validateEmail}
                />
                <PasswordInput
                    fullWidth
                    source='password'
                    validate={validateEditPassword}
                />
                <CheckboxGroupInput
                    label='Роли'
                    source='roles'
                    choices={[...RoleChoices]}
                    labelPlacement='bottom'
                />
            </SimpleForm>
        </Edit>
    );
};
