import {
    ContentPurposeEnum,
    ParagraphTypeEnum,
    RoleEnum,
} from '../types/base.types';

export const FILE_ICON_MAX_SIZE = 2000000;
export const FILE_IMG_MAX_SIZE = 5000000;
export const FILE_DOC_MAX_SIZE = 50000000;
export const FILE_VIDEO_MAX_SIZE = 80000000;

export const SINGLE_FILE_FIELD_NAME = 'file';

export const MULTIPLE_FILES_FIELD_NAME = 'files';

export const ContentPurposesTranslated: Record<ContentPurposeEnum, string> = {
    [ContentPurposeEnum.GALLERY]: 'Галерея',
    [ContentPurposeEnum.MENU]: 'Меню',
    [ContentPurposeEnum.FAQ]: 'Вопросы',
    [ContentPurposeEnum.TEAM]: 'Команда',
    [ContentPurposeEnum.DOC]: 'Документ',
    [ContentPurposeEnum.PAGES]: 'Страница',
};

export const ParagraphTypesTranslated: Record<ParagraphTypeEnum, string> = {
    [ParagraphTypeEnum.TEXT]: 'Текст/Описание',
    [ParagraphTypeEnum.ALERT]: 'Предупреждение',
    [ParagraphTypeEnum.PROMO]: 'Реклама',
    [ParagraphTypeEnum.NEW]: 'Новинка',
    [ParagraphTypeEnum.SOON]: 'Событие будущего',
};

export const RolesTranslated: Record<RoleEnum, string> = {
    [RoleEnum.ADMIN]: 'Админ',
    [RoleEnum.EDITOR]: 'Редактор',
    [RoleEnum.WATCHER]: 'Наблюдатель',
};

export const BASE_URL = 'https://api.rst360.ru';
export const API_URL = `${BASE_URL}/api`;
export const AUTH_URL = `${API_URL}/auth`;

export const ContentPurposeChoices = [
    {
        id: ContentPurposeEnum.MENU,
        name: ContentPurposesTranslated.menu,
    },
    {
        id: ContentPurposeEnum.FAQ,
        name: ContentPurposesTranslated.faq,
    },
    {
        id: ContentPurposeEnum.GALLERY,
        name: ContentPurposesTranslated.gallery,
    },
    {
        id: ContentPurposeEnum.TEAM,
        name: ContentPurposesTranslated.teammates,
    },
    {
        id: ContentPurposeEnum.DOC,
        name: ContentPurposesTranslated.documents,
    },
    {
        id: ContentPurposeEnum.PAGES,
        name: ContentPurposesTranslated.pages,
    },
];

export const ParagraphTypeChoices = [
    {
        id: ParagraphTypeEnum.TEXT,
        name: ParagraphTypesTranslated.text,
    },
    {
        id: ParagraphTypeEnum.ALERT,
        name: ParagraphTypesTranslated.alert,
    },
    {
        id: ParagraphTypeEnum.PROMO,
        name: ParagraphTypesTranslated.promo,
    },
    {
        id: ParagraphTypeEnum.NEW,
        name: ParagraphTypesTranslated.new,
    },
    {
        id: ParagraphTypeEnum.SOON,
        name: ParagraphTypesTranslated.soon,
    },
];

export const RoleChoices = [
    {
        id: RoleEnum.ADMIN,
        name: RolesTranslated.admin,
    },
    {
        id: RoleEnum.EDITOR,
        name: RolesTranslated.editor,
    },
    {
        id: RoleEnum.WATCHER,
        name: RolesTranslated.watcher,
    },
];

export const TOKEN_EXP_GAP = 5000;

export const SLUG_REGEX = /^[A-Za-z][A-Za-z0-9]*$/;
