import { ChangeEvent, FC, SetStateAction } from 'react';
import {
    BooleanInput,
    CheckboxGroupInput,
    ImageFieldProps,
    useEditContext,
    useRecordContext,
} from 'react-admin';
import { BASE_URL } from '../utils/const';
import { ImageContainer } from './ImageContainer';

interface Props extends ImageFieldProps {
    width?: string;
    height?: string;
    showAll?: boolean;
    source: string;
    deleteState?: {
        state: string[];
        callback: React.Dispatch<SetStateAction<any>>;
    };
}

export const CustomImageField: FC<Props> = ({
    source,
    showAll = false,
    deleteState = null,
    width = '',
    height = '',
    ...props
}) => {
    const record = useRecordContext();
    
    const onCheckboxDeleteChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        url: string
    ) => {
        if (deleteState) {
            const { state, callback: setState } = deleteState;
            if (e.currentTarget.checked) {
                setState([...state, url]);
            } else {
                setState([...state.filter((u) => u !== url)]);
            }
        }
    };

    if (Array.isArray(record[source])) {
        const length = record[source].length;
        return (
            <>
                {showAll && record[source].length > 0 ? (
                    <>
                        {deleteState && (
                            <span className='img-list-title'>
                                Текущие фотографии:
                            </span>
                        )}
                        <div className='img-list-grid'>
                            {record[source].map((url: string) => (
                                <div className='img-list-container' key={url}>
                                    <ImageContainer
                                        url={url}
                                        style={
                                            (width || height) && url
                                                ? { width, height }
                                                : {}
                                        }
                                    >
                                        {deleteState && (
                                            <label
                                                htmlFor={url}
                                                className='img-label-delete'
                                            >
                                                Удалить
                                                <input
                                                    id={url}
                                                    type={'checkbox'}
                                                    onChange={(e) => {
                                                        onCheckboxDeleteChange(
                                                            e,
                                                            url
                                                        );
                                                    }}
                                                />
                                            </label>
                                        )}
                                    </ImageContainer>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <ImageContainer
                            url={record[source][0]}
                            style={
                                (width || height) && record[source][0]
                                    ? { width, height }
                                    : {}
                            }
                        />

                        {length > 1 ? <span>{`Еще +${length - 1}`}</span> : ''}
                    </>
                )}
            </>
        );
    }
    return (
        <ImageContainer
            url={record[source]}
            style={(width || height) && record[source] ? { width, height } : {}}
        />
    );
};
