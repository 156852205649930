import { FC } from 'react';
import { SaveButton, SaveButtonProps } from 'react-admin';

export const CustomSaveButton: FC<SaveButtonProps> = (props) => {

    return (
        <SaveButton
            {...props}
            label={'Сохранить'}
            type='submit'
        
        />
    );
};
