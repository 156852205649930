import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    TextField,
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    BooleanField,
    RichTextField,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';
import { CustomTextField } from '../CustomTextField';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const MenuDetailsShow: FC<Props> = () => {
    return (
        <Show actions={<EditingTopToolbar withEdit />}>
            <SimpleShowLayout>
                <ReferenceField
                    source='item.id'
                    reference='menu/items'
                    label={'Позиция'}
                />

                <TextField source='id' />
                <TextField source='slogan' label='Слоган' />
                <CustomTextField
                    source='comment'
                    label='Комментарий'
                    emptyText='Отсутствует'
                />
                <RichTextField source='desc' label='Описание' />
                <CustomImageField
                    source='url'
                    label='Фото'
                    width='400px'
                    height='300px'
                />
                <BooleanField source='active' label='Активность' />

                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <DateField
                    showTime
                    source='createdDate'
                    label='Дата создания'
                />
            </SimpleShowLayout>
        </Show>
    );
};
