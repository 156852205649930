import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    useNotify,
    useRedirect,
    BooleanInput,
    TextInput,
} from 'react-admin';

import { FILE_VIDEO_MAX_SIZE, SINGLE_FILE_FIELD_NAME } from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateTitle } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const VideoCreate: FC<Props> = () => {
    const transform = (data: any) => {
        console.log(data)
        return {
            ...data,
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Добавить фотографии'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                    notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='title'
                    label='Название видео'
                    fullWidth
                    validate={validateTitle}
                />
                <BooleanInput source='promo' label='Промо' />
                <CustomFileInput
                    validate={required()}
                    isVideo
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Видео'
                    multiple={false}
                    placeholder='Перетащите видео или кликните для загрузки'
                    accept={[
                        'video/mp4',
                        'video/mpeg',
                        'video/m4v',
                        'video/webm',
                    ]}
                    helperText='Разрешены форматы: mp4|mpeg|m4v|webm. Файлы более 70мб не принимаются. Загрузить можно одно видео.'
                    maxSize={FILE_VIDEO_MAX_SIZE}
                />
            </SimpleForm>
        </Create>
    );
};
