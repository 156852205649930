import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    ShowButton,
    BooleanField,
} from 'react-admin';
import { BASE_URL } from '../../utils/const';
import { CustomListActions } from '../CustomListActions';
import { CustomVideoField } from '../CustomVideoField';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const VideoList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source='id' />
                <TextField source='title' label='Название видео' />
                <BooleanField source='promo' label='Промо' />
                <CustomVideoField
                    source='url'
                    base={BASE_URL}
                    withoutHeading
                    label={'Превью'}
                    width='100px'
                    height='60px'
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <EditButton label='РЕД.' />
                <ShowButton label='Смотреть' />
            </Datagrid>
        </List>
    );
};
