import { MULTIPLE_FILES_FIELD_NAME, SINGLE_FILE_FIELD_NAME } from './const';

export const determineDataContentType = (data: Record<any, any>) => {
    if (data[SINGLE_FILE_FIELD_NAME] || data[MULTIPLE_FILES_FIELD_NAME]) {
        const formdata = new FormData();
        Object.keys(data).forEach((k) => {
            if (k === SINGLE_FILE_FIELD_NAME) {
                formdata?.append(k, data[k].rawFile);
            } else if (k === MULTIPLE_FILES_FIELD_NAME) {
                data[k].forEach((f: any) => {
                    formdata.append(k, f.rawFile);
                });
            } else if (!data[k] && typeof data[k] !== 'boolean') {
                delete data[k];
            } else if (Array.isArray(data[k])) {
                data[k].forEach((i: any) => {
                    formdata.append(`${k}[]`, i);
                });
            } else {
                formdata?.append(k, data[k]);
            }
        });

        return formdata;
    }
    return JSON.stringify(data);
};
