import {
    DetailedHTMLProps,
    FC,
    HTMLAttributes,
    useEffect,
    useState,
} from 'react';
import {
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Edit,
    BooleanInput,
    DateTimeInput,
    ArrayInput,
    SimpleFormIterator,
    useGetOne,
} from 'react-admin';
import {
    FILE_IMG_MAX_SIZE,
    MULTIPLE_FILES_FIELD_NAME,
} from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { CustomImageField } from '../CustomImageField';
import {
    validateDesc,
    validatePath,
    validateShort,
    validateTitle,
} from './validators';
import { RichTextInput } from 'ra-input-rich-text';
import { useParams } from 'react-router-dom';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ProductUpdate: FC<Props> = () => {
    const { id } = useParams();
    const { data } = useGetOne('products', {
        id,
    });

    const [newRecord, setNewRecord] = useState<any>({});

    const [photosToDelete, setPhotosToDelete] = useState<string[]>([]);
    const [isEditPhotosEnable, setEditPhotosEnable] = useState(false);

    const transform = (data: any) => {
        console.log(data);
        const files = data.files;
        const videoId = Number(data.video.id);
        const desc = data.desc.map((d: any) => d.value);
        delete data.desc;
        delete data.files;
        delete data.photos;
        delete data.deleteEnable;
        delete data.video;
        return {
            ...data,
            desc,
            removeVideo: videoId ? false : true,
            videoId,
            photosToDelete,
            files: files || [],
        };
    };

    useEffect(() => {
        if (data) {
            setNewRecord({
                ...data,
                desc: data.desc.map((v: string) => ({ value: v })),
            });
        }
    }, [data]);

    useEffect(() => {
        if (!isEditPhotosEnable && photosToDelete.length > 0) {
            setPhotosToDelete([]);
        }
    }, [photosToDelete.length, isEditPhotosEnable]);

    return (
        <Edit
            actions={<EditingTopToolbar />}
            title={'Редактировать услугу'}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
                record={newRecord}
            >
                <TextInput
                    source='title'
                    label='Название услуги'
                    fullWidth
                    validate={validateTitle}
                    helperText='Не менее 3 символа и не более 100 символов.'
                />
                <TextInput
                    source='shortDesc'
                    label='Описание'
                    fullWidth
                    validate={validateShort}
                    helperText='Не более 150 символов.'
                />

                <ArrayInput
                    source='desc'
                    label='Подробное описание (Нужно, если подключен динамический путь)'
                    className='array-input'
                    fullWidth
                    helperText='Параграфы переносятся на отдельную строку на сайте. Максимум 5 параграфов. Не более 1000 символов в каждом.'
                >
                    <SimpleFormIterator
                        fullWidth
                        inline
                        className='iterator'
                        getItemLabel={(index) => `${index + 1}.`}
                    >
                        <RichTextInput
                            style={{ color: 'black' }}
                            source='value'
                            fullWidth
                            validate={validateDesc}
                            className='rich-text'
                            label={false}
                            toolbar={<span className='rich-text' />}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                    source='path'
                    label='Динамический путь (опционально)'
                    fullWidth
                    validate={validatePath}
                    helperText='Если указан, то на сайте будет создан переход на отдельную страницу. '
                />

                <DateTimeInput
                    source='startDate'
                    label='Когда начинается (опционально)'
                    helperText='Не более 1200 символов.'
                />
                <DateTimeInput
                    source='endDate'
                    label='Когда заканчивается (опционально)'
                    helperText='Если указать, то на сайте будет скрыта услуга по истечении срока'
                />
                <BooleanInput
                    source='active'
                    label='Активность'
                    helperText='Отключает отображение на сайте в любом случае.'
                    fullWidth
                    defaultValue={true}
                />

                <ReferenceInput source='video.id' reference='gallery/videos'>
                    <SelectInput
                        label='Видео (опционально)'
                        optionText='title'
                        fullWidth
                        helperText={''}
                        emptyText='<<Без видео>>'
                    />
                </ReferenceInput>

                <CustomFileInput
                    isImage
                    source={MULTIPLE_FILES_FIELD_NAME}
                    label='Фото'
                    multiple={true}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Файлы более 5мб не принимаются. Загрузить можно до 10 изображений.'
                    maxSize={FILE_IMG_MAX_SIZE}
                    noUploadedAlready
                />
                <BooleanInput
                    className='margin-input'
                    source='deleteEnable'
                    label='Редактировать текущие фото'
                    onChange={(e) => {
                        setEditPhotosEnable(!isEditPhotosEnable);
                    }}
                    defaultValue={isEditPhotosEnable}
                />
                {isEditPhotosEnable && (
                    <CustomImageField
                        source='photos'
                        showAll
                        deleteState={{
                            state: photosToDelete,
                            callback: setPhotosToDelete,
                        }}
                    />
                )}
            </SimpleForm>
        </Edit>
    );
};
