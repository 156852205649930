import { FC } from 'react';
import {
    SimpleShowLayout,
    RichTextField,
    SimpleShowLayoutProps,
    RichTextFieldProps,
} from 'react-admin';

interface Props extends RichTextFieldProps {}

export const CustomExpandText: FC<Props> = ({ ...props }) => (
    <SimpleShowLayout>
        <RichTextField {...props} />
    </SimpleShowLayout>
);
