import {
    required,
    maxLength,
    minValue,
    minLength,
    maxValue,
} from 'react-admin';

export const validatePrice = [
    required(),
    maxValue(99999999, 'Максимум 99999999'),
    minValue(1, 'Минимум 1'),
];

export const validateText = [
    maxLength(100, 'Максимум 100 символов'),
    minLength(3, 'Минимум 3 символа'),
];
