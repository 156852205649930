import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    SelectInput,
    useNotify,
    useRedirect,
} from 'react-admin';
import {
    TeammatesPoisitionEnum,
} from '../../types/base.types';
import { FILE_IMG_MAX_SIZE, SINGLE_FILE_FIELD_NAME } from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateSlogan, validateDesc, validateName } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const TeammateCreate: FC<Props> = () => {
    const transform = (data: any) => {
        return {
            ...data,
            file: data.file || {},
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Добавить работника'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                   notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='name'
                    label='Имя'
                    fullWidth
                    validate={validateName}
                />
                <TextInput
                    source='desc'
                    label='Описание'
                    fullWidth
                    validate={validateDesc}
                />
                <TextInput
                    source='slogan'
                    label='Слоган'
                    fullWidth
                    validate={validateSlogan}
                />

                <SelectInput
                    fullWidth
                    source='position'
                    label='Должность'
                    validate={required()}
                    choices={[
                        {
                            id: TeammatesPoisitionEnum.CHEF,
                            name: TeammatesPoisitionEnum.CHEF,
                        },
                        {
                            id: TeammatesPoisitionEnum.COOKER,
                            name: TeammatesPoisitionEnum.COOKER,
                        },
                        {
                            id: TeammatesPoisitionEnum.WAITER,
                            name: TeammatesPoisitionEnum.WAITER,
                        },
                        {
                            id: TeammatesPoisitionEnum.BARMAN,
                            name: TeammatesPoisitionEnum.BARMAN,
                        },
                        {
                            id: TeammatesPoisitionEnum.MANAGER,
                            name: TeammatesPoisitionEnum.MANAGER,
                        },
                        {
                            id: TeammatesPoisitionEnum.DIRECTOR,
                            name: TeammatesPoisitionEnum.DIRECTOR,
                        },
                        {
                            id: TeammatesPoisitionEnum.HOSTES,
                            name: TeammatesPoisitionEnum.HOSTES,
                        },
                    ]}
                />
                <CustomFileInput
                    validate={required()}
                    isImage
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Фото'
                    multiple={false}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Файлы более 5мб не принимаются. Загрузить можно только один файл.'
                    maxSize={FILE_IMG_MAX_SIZE}
                />
            </SimpleForm>
        </Create>
    );
};
