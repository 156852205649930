import {
    MdOutlineCategory,
    MdOutlinePhotoLibrary,
    MdOutlineRestaurantMenu,
} from 'react-icons/md';
import { BsCameraVideo, BsCashStack, BsChatSquareText } from 'react-icons/bs';
import { AiOutlineFilePdf, AiOutlineFire, AiOutlineTeam } from 'react-icons/ai';
import { FaQuestion } from 'react-icons/fa';
import { GrUserAdmin } from 'react-icons/gr';
import { FcCustomerSupport } from 'react-icons/fc';
import { GrServicePlay } from 'react-icons/gr';
import { MdOutlineSell } from 'react-icons/md';

export const CategoryIcon = () => {
    return <MdOutlineCategory size={'24px'} />;
};
export const MenuIcon = () => {
    return <MdOutlineRestaurantMenu size={'24px'} />;
};

export const DetailsIcon = () => {
    return <AiOutlineFire size={'24px'} />;
};

export const TeamIcon = () => {
    return <AiOutlineTeam size={'24px'} />;
};

export const GalleryIcon = () => {
    return <MdOutlinePhotoLibrary size={'24px'} />;
};

export const VideoIcon = () => {
    return <BsCameraVideo size={'24px'} />;
};

export const QuestionsIcon = () => {
    return <FaQuestion size={'22px'} />;
};

export const DocsIcon = () => {
    return <AiOutlineFilePdf size={'22px'} />;
};

export const AdminIcon = () => {
    return <GrUserAdmin size={'20px'} />;
};

export const CustomerIcon = () => {
    return <FcCustomerSupport size={'24px'} />;
};

export const ServiceIcon = () => {
    return <GrServicePlay size={'22px'} />;
};

export const VariantsIcon = () => {
    return <MdOutlineSell size={'22px'} />;
};

export const PlansIcon = () => {
    return <BsCashStack size={'22px'} />;
};

export const ParagraphsIcon = () => {
    return <BsChatSquareText size={'22px'} />;
};

