import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRedirect,
    BooleanInput,
} from 'react-admin';
import { FILE_IMG_MAX_SIZE, SINGLE_FILE_FIELD_NAME } from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateSlogan, validateComment, validateDesc } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const MenuDetailsCreate: FC<Props> = () => {
    const transform = (data: any) => {
        const itemId = Number(data.item.id);
        delete data.item;
        return {
            ...data,
            itemId,
            comment: data.comment || '',
            file: data.file || {},
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Создать топ-позицию в меню'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                   notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <ReferenceInput
                    source='item.id'
                    reference='menu/items/no-details'
                >
                    <SelectInput
                        validate={required()}
                        label='Позиция'
                        optionText='title'
                        fullWidth
                        helperText={
                            'При отсутствии позиций, значит, все имеющиеся позиции в топе'
                        }
                    />
                </ReferenceInput>
                <TextInput
                    source='slogan'
                    label='Слоган'
                    fullWidth
                    validate={validateSlogan}
                    helperText='Не более 100 символов.'
                />
                <TextInput
                    source='comment'
                    label='Комментарий'
                    fullWidth
                    validate={validateComment}
                    helperText='Не более 80 символов.'
                />
                <TextInput
                    source='desc'
                    label='Описание'
                    fullWidth
                    validate={validateDesc}
                    helperText='Не более 300 символов.'
                />
                <BooleanInput
                    source='active'
                    label='Активность'
                    fullWidth
                    defaultValue={true}
                />

                <CustomFileInput
                    isImage
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Фото'
                    multiple={false}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Файлы более 5мб не принимаются. Загрузить можно только один файл.'
                    maxSize={FILE_IMG_MAX_SIZE}
                />
            </SimpleForm>
        </Create>
    );
};
