import { required, maxLength, minLength, regex } from 'react-admin';
import { SLUG_REGEX } from '../../utils/const';

export const validateTitle = [
    required(),
    maxLength(100, 'Максимум 100 символов'),
    minLength(3, 'Минимум 3 символа'),
];

export const validateShort = [
    required(),
    maxLength(150, 'Максимум 150 символов'),
    minLength(3, 'Минимум 3 символа'),
];


export const validatePath = [
    minLength(1),
    maxLength(14),
    regex(SLUG_REGEX, 'Только английские буквы и цифры'),
];

export const validateDesc = [
    required(),
    maxLength(1000, 'Максимум 1000 символов в одном параграфе'),
    minLength(3, 'Минимум 3 символов'),
];
