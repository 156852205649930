import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    ReferenceField,
    ShowButton,
    BooleanField,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';
import { CustomListActions } from '../CustomListActions';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const PhotoList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid rowClick={'edit'}>
                <TextField source='id' />
                <BooleanField source='intro' label='Для интро' />
                <BooleanField source='collage' label='Для коллажа' />
                <ReferenceField
                    label='Категория'
                    source='category.id'
                    reference='categories'
                />
                <CustomImageField
                    source='url'
                    label={'Фото'}
                    width='100px'
                    height='60px'
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <EditButton label='РЕД.' />
                <ShowButton label='Смотреть' />
            </Datagrid>
        </List>
    );
};
