import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRedirect,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';
import {
    FILE_DOC_MAX_SIZE,
    SINGLE_FILE_FIELD_NAME,
} from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateTitle, validateComment } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const DocCreate: FC<Props> = () => {
    const transform = (data: any) => {
        return {
            ...data,
            comment: data.comment || '',
        };
    };

    const redirect = useRedirect();
    const notify = useNotify();

    return (
        <Create
            title={'Создать вопрос'}
            actions={<EditingTopToolbar />}
            transform={transform}
            mutationOptions={{
                onSuccess: (data: any, v: any, ctx: any) => {
                    redirect(`/${v.resource}`);
                  notify('Изменения успешно введены!', { type: 'success' });
                },
            }}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='title'
                    label='Название'
                    fullWidth
                    validate={validateTitle}
                />
                <TextInput
                    source='comment'
                    label='Комментарий'
                    fullWidth
                    validate={validateComment}
                />

                <ReferenceInput
                    source='categoryId'
                    reference='categories'
                    filter={{ purpose: ContentPurposeEnum.DOC }}
                >
                    <SelectInput
                        validate={required()}
                        label='Категория'
                        fullWidth
                    />
                </ReferenceInput>
                <CustomFileInput
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Документ'
                    multiple={false}
                    placeholder='Перетащите документ или кликните для загрузки'
                    helperText='Разрешены форматы: pdf|doc|xls|docx|xlsx|rtf|odt|zip|rar|jpg|jpeg|png|gif. Файлы более 50мб не принимаются. Загрузить можно только один файл.'
                    maxSize={FILE_DOC_MAX_SIZE}
                />
            </SimpleForm>
        </Create>
    );
};
