import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    TextField,
    DateField,
    EditButton,
    ReferenceField,
    Show,
    SimpleShowLayout,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';
import { CustomListActions } from '../CustomListActions';
import { EditingTopToolbar } from '../EditingTopToolbar';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const MenuItemShow: FC<Props> = () => {
    return (
        <Show actions={<EditingTopToolbar withEdit />}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='title' label='Название' />
                <TextField source='price' label='Цена' />
                <ReferenceField
                    label='Категория'
                    source='category.id'
                    reference='categories'
                />
                <CustomImageField
                    source='url'
                    label='Фото'
                    width='400px'
                    height='300px'
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <DateField
                    showTime
                    source='createdDate'
                    label='Дата создания'
                />
            </SimpleShowLayout>
        </Show>
    );
};
