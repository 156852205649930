import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    UrlField,
    ListActions,
    EditButton,
    ReferenceField,
    ShowButton,
} from 'react-admin';
import { CustomExpandText } from '../CustomExpandText';
import { CustomImageField } from '../CustomImageField';
import { CustomListActions } from '../CustomListActions';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const TeammateList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid
                rowClick={'expand'}
                expand={<CustomExpandText source='desc' label='Описание' />}
            >
                <TextField source='id' />
                <TextField source='name' label='Имя' />
                <TextField source='position' label='Должность' />
                <CustomImageField
                    source='url'
                    label={'Фото'}
                    width='100px'
                    height='60px'
                />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <EditButton label='РЕД.' />
                <ShowButton label='Смотреть' />
            </Datagrid>
        </List>
    );
};
