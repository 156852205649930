import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    ReferenceField,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';
import { CustomListActions } from '../CustomListActions';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ProductPlanList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid rowClick={'edit'} size='small' optimized>
                <ReferenceField
                    label='Вариант продаж'
                    source='variant.id'
                    reference='product-variants'
                />
                <TextField source='id' />

                <TextField source='price' label='Цена' />
                <TextField source='desc' label='Описание' />
                <TextField source='comment' label='Пояснения' />
                <CustomImageField
                    source='icon'
                    width='60px'
                    height='60px'
                    label='Иконка'
                />
                <DateField showTime source='updatedDate' label='Обновление' />
                <EditButton label='РЕД.' />
            </Datagrid>
        </List>
    );
};
