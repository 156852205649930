import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    ReferenceField,
    ShowButton,
    BooleanField,
} from 'react-admin';
import { CustomImageField } from '../CustomImageField';
import { CustomListActions } from '../CustomListActions';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ProductList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid rowClick={'show'} size='small' optimized>
                <TextField source='id' />
                <TextField source='title' label='Название' />

                <ReferenceField
                    label='Видео'
                    source='video.id'
                    reference='gallery/videos'
                    emptyText='---'
                />
                <CustomImageField
                    source='photos'
                    label='Фото'
                    width='100px'
                    height='60px'
                />
                <BooleanField source='active' label='Активность' />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <EditButton label='РЕД.' />
                <ShowButton label='Смотреть' />
            </Datagrid>
        </List>
    );
};
