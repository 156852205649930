import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Edit,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';
import {
    FILE_DOC_MAX_SIZE,
    SINGLE_FILE_FIELD_NAME,
} from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateTitle, validateComment } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const DocUpdate: FC<Props> = () => {

    const transform = (data: any) => {
        return {
            ...data,
            categoryId: data.category.id,
            comment: data.comment || '',
            file: data.file || {},
        };
    };

    return (
        <Edit
            title={'Редактировать вопрос'}
            actions={<EditingTopToolbar />}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='title'
                    label='Название'
                    fullWidth
                    validate={validateTitle}
                />
                <TextInput
                    source='comment'
                    label='Комментарий'
                    fullWidth
                    validate={validateComment}
                />

                <ReferenceInput
                    source='category.id'
                    reference='categories'
                    filter={{ purpose: ContentPurposeEnum.DOC }}
                >
                    <SelectInput
                        validate={required()}
                        label='Категория'
                        fullWidth
                    />
                </ReferenceInput>
                <CustomFileInput
                    returnState
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Изменить текущий документ'
                    multiple={false}
                    placeholder='Перетащите документ или кликните для загрузки'
                    helperText='Разрешены форматы: pdf|doc|xls|docx|xlsx|rtf|odt|zip|rar|jpg|jpeg|png|gif. Файлы более 50мб не принимаются. Загрузить можно только один файл.'
                    maxSize={FILE_DOC_MAX_SIZE}
                />
            </SimpleForm>
        </Edit>
    );
};
