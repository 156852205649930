import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    EditButton,
    useRecordContext,
    SimpleShowLayout,
    ReferenceField,
} from 'react-admin';
import { CustomListActions } from '../CustomListActions';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ExpandTemplate: FC<Props> = ({ ...props }) => {
    const record = useRecordContext();
    return (
        <SimpleShowLayout>
            <ul>
                <b>Ограничения:</b>
                {record.limits.map((l: string) => (
                    <li key={l}>{l}</li>
                ))}
            </ul>

            <ul>
                <b>Преимущества:</b>

                {record.additions.map((a: string) => (
                    <li key={a}>{a}</li>
                ))}
            </ul>

            <TextField label='Описание' record={record} source='shortDesc' />
        </SimpleShowLayout>
    );
};

export const ProductVariantList: FC<Props> = () => {
    return (
        <List
            sort={{ field: 'updatedDate', order: 'ASC' }}
            actions={<CustomListActions />}
        >
            <Datagrid
                rowClick={'expand'}
                size='small'
                optimized
                expand={<ExpandTemplate />}
            >
                <ReferenceField
                    label='Услуга'
                    source='item.id'
                    reference='products'
                />
                <TextField source='id' />
                <TextField source='title' label='Название' />
                <BooleanField source='payment' label='С оплатой' />
                <BooleanField source='active' label='Активность' />
                <DateField
                    showTime
                    source='updatedDate'
                    label='Последнее обновление'
                />
                <EditButton label='РЕД.' />
            </Datagrid>
        </List>
    );
};
