import { required, maxLength, minValue } from 'react-admin';

export const validateTitle = [
    required(),
    maxLength(80, 'Максимум 80 символов'),
    minValue(3),
];
export const validateAnswer = [
    required(),
    maxLength(1500, 'Максимум 1500 символов'),
    minValue(3),
];
