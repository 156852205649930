import { DetailedHTMLProps, FC, HTMLAttributes, useState } from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    Edit,
    BooleanInput,
    useEditController,
} from 'react-admin';
import { ContentPurposeEnum } from '../../types/base.types';
import { FILE_IMG_MAX_SIZE, SINGLE_FILE_FIELD_NAME } from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateTitle, validateAnswer } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const QuestionsUpdate: FC<Props> = () => {
    const { record } = useEditController();
    const [isDeleteAvailable, setDeleteAvailable] = useState(true);

    const transform = (data: any) => {
        return {
            ...data,
            categoryId: data.category.id,
            file: data.file || {},
        };
    };

    return (
        <Edit
            title={'Редактировать вопрос'}
            actions={<EditingTopToolbar />}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='title'
                    label='Название'
                    fullWidth
                    validate={validateTitle}
                />
                <TextInput
                    source='answer'
                    label='Ответ'
                    fullWidth
                    validate={validateAnswer}
                />

                <ReferenceInput
                    source='category.id'
                    reference='categories'
                    filter={{ purpose: ContentPurposeEnum.FAQ }}
                >
                    <SelectInput
                        validate={required()}
                        label='Категория'
                        fullWidth
                    />
                </ReferenceInput>
                <CustomFileInput
                    isImage
                    source={SINGLE_FILE_FIELD_NAME}
                    label='Изменить или добавить фото'
                    multiple={false}
                    placeholder='Перетащите фотографии или кликните для загрузки'
                    accept={'image/*'}
                    helperText='Файлы более 5мб не принимаются. Загрузить можно только один файл.'
                    maxSize={FILE_IMG_MAX_SIZE}
                    deleteCb={setDeleteAvailable}
                />
                {isDeleteAvailable && (
                    <BooleanInput
                        source='deletePhoto'
                        label='Удалить текущее фото'
                        fullWidth
                        defaultValue={false}
                        disabled={record && !record.url}
                        helperText={
                            record && !record.url
                                ? 'Текущее фото отсутствует'
                                : ''
                        }
                    />
                )}
            </SimpleForm>
        </Edit>
    );
};
