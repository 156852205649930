import { DetailedHTMLProps, FC, HTMLAttributes, useState } from 'react';
import {
    SimpleForm,
    TextInput,
    Edit,
    ReferenceInput,
    required,
    SelectInput,
    BooleanInput,
    useEditController,
} from 'react-admin';
import {
    FILE_ICON_MAX_SIZE,
    SINGLE_FILE_FIELD_NAME,
} from '../../utils/const';
import { CustomFileInput } from '../CustomFileInput';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validatePrice, validateText } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const ProductPlanUpdate: FC<Props> = () => {
    const { record } = useEditController();
    const [isDeleteAvailable, setDeleteAvailable] = useState(true);

    const transform = (data: any) => {
        const deleteIcon = data.deleteIcon && isDeleteAvailable;
        delete data.deleteIcon;
        return {
            ...data,
            deleteIcon,
            file: data.file || {},
            variantId: data.variant.id,
        };
    };

    return (
        <Edit
            actions={<EditingTopToolbar />}
            title={'Редактировать админа'}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <ReferenceInput
                    source='variant.id'
                    reference='product-variants'
                >
                    <SelectInput
                        validate={required()}
                        label='Вариант продаж'
                        optionText='title'
                        fullWidth
                        helperText={
                            'Ценовое предложение должно прикрепляться к одному из вариантов продаж'
                        }
                    />
                </ReferenceInput>

                <TextInput
                    source='desc'
                    label='Описание'
                    fullWidth
                    validate={validateText}
                />
                <TextInput
                    source='price'
                    label='Цена'
                    fullWidth
                    validate={validatePrice}
                />
                <TextInput
                    source='comment'
                    label='Пояснения'
                    fullWidth
                    validate={validateText}
                />
                <CustomFileInput
                    isImage
                    source={SINGLE_FILE_FIELD_NAME}
                    deleteCb={setDeleteAvailable}
                    returnState
                    currentUrl='icon'
                    label='Иконка'
                    multiple={false}
                    placeholder='Перетащите иконку или кликните для загрузки'
                    accept={['image/svg', 'image/webp', 'image/png']}
                    helperText='Файлы более 2мб не принимаются. Загрузить можно 1 иконку. Форматы: svg, png, webp'
                    maxSize={FILE_ICON_MAX_SIZE}
                />
                {isDeleteAvailable && (
                    <BooleanInput
                        source='deleteIcon'
                        label='Удалить текущее фото'
                        fullWidth
                        defaultValue={false}
                        disabled={record && !record.icon}
                        helperText={
                            record && !record.icon
                                ? 'Текущая иконка отсутствует'
                                : ''
                        }
                    />
                )}
            </SimpleForm>
        </Edit>
    );
};
