import { DetailedHTMLProps, FC, HTMLAttributes, useState } from 'react';
import {
    SimpleForm,
    Edit,
    BooleanInput,
    useEditController,
    TextField,
    TextInput,
    FileField,
} from 'react-admin';
import { BASE_URL } from '../../utils/const';
import { CustomImageField } from '../CustomImageField';
import { CustomVideoField } from '../CustomVideoField';
import { EditingToolbar } from '../EditingToolbar';
import { EditingTopToolbar } from '../EditingTopToolbar';
import { validateTitle } from './validators';

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const VideoUpdate: FC<Props> = () => {
    const transform = (data: any) => {
        return {
            ...data,
        };
    };

    return (
        <Edit
            title={'Редактировать фото'}
            actions={<EditingTopToolbar />}
            transform={transform}
        >
            <SimpleForm
                toolbar={<EditingToolbar />}
                mode='onSubmit'
                reValidateMode='onSubmit'
            >
                <TextInput
                    source='title'
                    label='Название видео'
                    fullWidth
                    validate={validateTitle}
                />
                <BooleanInput source='promo' label='Промо' />
                <CustomVideoField
                    source='url'
                    base={BASE_URL}
                    label={'Превью'}
                />
                <FileField source='url' />
            </SimpleForm>
        </Edit>
    );
};
